@use postcss-color-function;
@use postcss-nested;
@import url('https://fonts.googleapis.com/css?family=Raleway:400,700,900');

.row .body {
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 20px;
  margin-bottom: 0px;
  justify-content: center;
}

.bg-image {
  background-image: url("mainPhoto.jpg");
  /*display: flex;*/
  /*flex-direction: column;*/
  /*flex-basis: 100%;*/
  /*min-height: 75vh;*/
  /*max-height: 100vh;*/
  height: 80vh;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.bg-bg {
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(8px);
  height: 100%;


  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /*padding-top: 70px;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-text {
  background-color: rgb(255, 255, 255); /* Fallback color */
  background-color: rgba(
    238,
    238,
    238,
    0.797
  ); /* Black w/opacity/see-through */
  color: rgb(0, 0, 0);
  font-weight: bold;
  border: 3px solid #f1f1f1;
  /*position: absolute;*/
  z-index: 1;
  /*margin-top: 1%;*/
  /*margin-left: 10%;*/
  width: 60%;
  /*height: 100%;*/
  padding: 20px 5px 40px;
  text-align: center;
  font-size: 1.5vw;
  border-radius: 999px;
}
.bg-text .title-home {
  font-size: 6vw;
  color: rgb(162, 24, 9) !important;
}

.bg-text .search {
  position: relative;
  display:inline-block;
  font-size: 1vw;
  border-radius: 25px;
  border: 2px solid #575756;
  padding-left: 10px;
  z-index: 2;
  width: 40%;
  background-color: transparent;
}

.bg-text .search input{
  position:relative;
  font-size: 2vw;
  text-align: left;
  /*z-index: 3;*/
  border:none;
  background-color: transparent;
  outline:none;

}
.bg-text .search button{
    position: absolute;
    top:0.3vw;
    left: 9vw;
    width: 100%;
    height: 80%;
    /*top: 34vw;*/
    display: flex;
    /*z-index:4;*/
    /*right: 39%;*/
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size:contain;
    padding: 1.5% 2%;
    border: none;
    background-color: transparent;
    /*transition: all 250ms ease-in-out;*/
    backface-visibility: hidden;
    /*transform-style: preserve-3d;*/
    /*margin-right:0px;*/
}

@media all and (max-width: 767px) { 
  .bg-text {
    width: 80%; 
    padding: 20px 5px; 
    font-size: 4vw; 
  }
  
  .bg-text .title-home {
    font-size: 8vw; 
  }
  
  .bg-text .search input {
    font-size: 3.5vw; 
  }
}
