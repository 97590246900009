.body {
  text-align: center;
  color: black !important;
}
.body p {
  margin-top: 50px;
  font-size: 25pt;
  color: black !important;
}
.body .info {
  margin-top: 50px;
  font-size: 15pt;
  color: black !important;
}
.title {
  margin-top: 50px;
  text-align: center;
  color: black !important;
}
.title2 {
  text-align: center;
  color: black !important;
}

.form-group {
  padding-top: 20px;
  padding-bottom: 20px;
  color: black !important;
}

.button {
  border-radius: 8px;
  padding: 10px 24px;
  /*margin-bottom: 200px;*/
  font-size: 18px;
  transition-duration: 0.4s;
  border: 2px solid #242424;
  cursor: pointer;
  text-align: center;
}

.button:hover {
  background-color: #720c0c; /* Green */
  color: white;
}

.contact-section {
  margin-bottom: 50px;
}
