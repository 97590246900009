.categoryName {
    font-weight: 800;
    font: 3vw;
}

.options {
    display: grid;
    grid-template-columns: 1fr;
}

.input {
    margin-right: 10px;
}
  