.accordion-header{
    height: 10%
}

.accordion-button {
    font-size: 12px;
    padding: 5px 10px;
}

.accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #990000;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-body {
    font-size: 10px; 
    padding: 5px;
}

.accordion-item {
    width: 60%;
    height: min-content;
}
