.main-content {
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 20px;
}

.searchBar {
  display: grid;
  grid-template-columns: 8fr 1fr;
  column-gap: 10px;
  padding: 10px;
}

.searchButton {
  background-color: rgb(137, 28, 16) !important;
  border-color: rgb(137, 28, 16) !important;
}

.resultsTitle{
  margin-top:6vw;
  text-align:center;
}

.resultsText{
  font-size: clamp(16px, 2vw, 24px);
  margin-left: 20px;
  font-weight: bold;
  text-align: left;
}

.dropdown-filter {
  display: none;
}

/* Mobile */
@media (max-width: 768px) {
  .default-filter {
    display: none;
  }

  .dropdown-filter {
    display: block;
  }
}

