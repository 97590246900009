.footer {
  padding: 0;
  /*align-self: end;*/
  background-color: rgb(120, 26, 15);
  /*height: 200px;*/
}

.col.onee {
  /* background-color: rgb(57, 57, 57); */
  color: white;
  padding: 0;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5%;
}
.col.onee img {
  width: 60%;
  height: auto;
  float: left;
}
.col.twwo {
  /* background-color: rgb(57, 57, 57); */
  color: white;
  padding: 0;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5%;
}

.col.twwo img {
  width: 30%;
  height: auto;
  float: right;
}

@media only screen and (max-width: 768px) {
  .col.onee img {
    width: 100%; 
    height: 100%;
  }
}

.copyRight {
  background-color: rgb(34, 34, 34);
  color: white;
  text-align: center;
  padding-bottom: 20px;
  left: 0px;
  right: 0px;
}

.container-fluid {
  padding: 0px;
}
